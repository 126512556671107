import React from 'react';
import {Button, Table} from 'antd';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import {navigate} from 'gatsby';
import URL_PATH from '../../UrlPath';
import styled from 'styled-components';
import {format} from '../../utils/date';

const tableColumns = [
  {
    title: '使用者名稱',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: '註冊時間',
    dataIndex: 'created',
    key: 'created',
    render: (_, record, idx) => {
      return format(record.created, 'YYYY-MM-DD HH:mm');
    },
    sorter: (a, b) => a.created - b.created,
  },
  {
    title: '更新時間',
    dataIndex: 'updated',
    key: 'updated',
    render: (_, record, idx) => {
      return format(record.updated, 'YYYY-MM-DD HH:mm');
    },
    sorter: (a, b) => a.updated - b.updated,
  },
];

function StaffsPage(props) {
  const app = React.useContext(AppContext.Context);
  const [isLoading, setIsLoading] = React.useState(false);
  const [records, setRecords] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      await _updateList();
      AppActions.setLoading(false);
    }

    if (app.staffUser) {
      fetchData();
    }
  }, [app.actions, app.staffUser]);

  const _updateList = React.useCallback(async () => {
    setIsLoading(true);
    try {
      let _resp = await app.actions.staffGetStaffs();
      setRecords(_resp);
    } catch (ex) {
      console.warn(ex);
    }
    setIsLoading(false);
  }, [app.actions]);

  return (
    <Wrapper>
      <h2>後台人員列表</h2>
      <Table
        locale={{
          triggerDesc: '排序遞減',
          triggerAsc: '排序遞增',
          cancelSort: '取消排序',
        }}
        loading={isLoading}
        dataSource={records}
        columns={tableColumns}
        rowKey={(record) => record.username}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & > h2 {
    margin-bottom: 16px;
  }
`;

export default StaffsPage;
